import './App.css';
import ProfilePage from './ProfilePage';

function App() {
  return (
    ProfilePage()
  );
}

export default App;
